import type {
  Response as CFResponse,
  RequestInfo as CFRequestInfo,
} from '@cloudflare/workers-types'

export function getFetchUrl(input: RequestInfo | CFRequestInfo | URL) {
  return (
    typeof input === 'string' ? input :
    'url' in input ? input.url :
    input.toString()
  )
}

export function getFetchPath(input: RequestInfo | CFRequestInfo | URL) {
  const url = getFetchUrl(input)

  if (url.startsWith('/') || url.startsWith(__appUrl__)) {
    const path = URL.canParse(url) ?
      new URL(url).pathname :
      url

    return path
  }

  return url
}


export function getSize(requestOrResponse: RequestInit | Response | CFResponse | undefined) {
  return Number.castInt(getHeader(requestOrResponse, 'content-length'))
}

export function getHeader(requestOrResponse: RequestInit | Response | CFResponse | undefined, name: string) {
  if (!requestOrResponse) {
    return
  }

  const { headers } = requestOrResponse

  if (!headers) {
    return
  }

  name = name.toLowerCase()
  
  if (Array.isArray(headers) || Object.isObject(headers)) {
    const entries = Array.isArray(headers) ? headers : Object.entries(headers)

    for (const [key, value] of entries) {
      if (key.toLowerCase() === name) {
        return value
      }
    }

  } else {
    return headers.get(name) ?? undefined
  }
}