type InvokeFn<V, A extends readonly unknown[], T> = (this: T, ...args2: A) => V
/**
 * If the first argument is a function, it is called with the remaining
 * arguments. If the first argument is not a function, is returned normally.
 * Useful for parameters that can be either a value or a function that generates
 * a value.
 *
 * @example
 * invoke(1, 2) // 1
 * invoke(v => v, 2) // 2
 * invoke((a, b) => [a, b], 2, 3) // [2, 3]
 */
export function invoke<V, A extends unknown[], T>(this: T, value: V | InvokeFn<V, A, T>, ...args: A): V {
  if (typeof value === 'function') {
    return (value as InvokeFn<V, A, T>).call(this, ...args)
  }

  return value as any
}